 .report,
 .report li {
     display: flex;
     padding: 0;
     margin: 0;
 }
 .photos {
  height: 150px;
  width: 150px;
 }
 .report {
     width: 100%;
     list-style: none;
     flex-wrap: wrap;
 }
 
 .report li {
     flex-basis: 25%;
     flex-shrink: 0;
     flex-grow: 1;
 }
 
 .report a {
     display: flex;
     justify-content: left;
     align-items: center;
     width: 100%;
     padding: .5em 1em;
     font-weight: 500;
     text-decoration: none;
     color: rgb(16, 16, 16);
     border: 3;
 }

 .report p {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    padding: .5em 1em;
    font-weight: 500;
    text-decoration: none;
    color: #FFF;
    border: 3;
}
 
 .report i {
     margin-right: .5em;
 }

 .report li {
	margin: 2px;
}

.bxicon {
    border:1rem;
    border-color: black;
    border-radius: 1px;
    background-color: burlywood;
    max-width: 3rem;
    max-height: 3rem;
}

.bx-icon-text {
    font-weight: bold;
    text-align: center;
    color: #222b45;
    font-size:16px;
}

.sticon {
    width: 100%;
    font-size: 5rem;
    justify-content: center;
}

.rep-date {
    text-align:end;
    color: rgb(0, 0, 0);
    font-family: Open Sans, sans-serif;
    font-size: 0.9375rem;
}

.rep-end {
    text-align:center;
    font-size: 0.9375rem;
    color: rgb(0, 0, 0);
    font-family: Open Sans, sans-serif;;
}

.rep-info {
    text-align:center;
    font-size: 0.9375rem;
    color: rgb(0, 0, 0);
    font-weight:bold
}

.rep-name {
    text-align:center;
    font-size: 1.25rem;
    color: rgb(0, 0, 0);
    font-weight:bold
}

.grid-icon {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    
  }
  .icon-date-text {
    text-align: center;
    font-size: 9px;
    color: #222b459f;
    margin-left: auto;
}
  .grid-err-icon {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    text-align: center;
    margin-left: auto;
    width: 200px;
    
  }
  .icon-err{
    margin-left: 24px;
  }
 .grid-gallery-icon{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
 }
  
  .box-icon-yellow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    color: black;
    border-radius: 0.6rem;
    background-color: #C5A502;
  }
  
  .box-icon-red {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    color: #FFF1F1;
    border-radius: 0.6rem;
    background-color: #DB2826;
  }
  
  .box-icon-black {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    color: #DB2826;
    border-radius: 0.6rem;
    background-color: rgb(0, 0, 0);
  }

  .box-icon-purple {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    color: #FFF;
    border-radius: 0.6rem;
    background-color: #9b24ff;
  }

  .text-cnpj {
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
    color: #00008b;
  }

  .text-title {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #222b45;
  }

  .text-subtitle {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #222b45;
  }

  .text-ship {
    text-align: right;
    font-size: 1.10rem;
    font-weight: bold;
    margin-top: 24px;
    color: hsla(0, 0%, 0%, 0.384);
  }

  .text-date {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #222b45b2;
  }

  .text-name-report {
    display: flex;
    justify-content:center;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    color: #222b45;
  }

  .text-date-header {
    display: flex;
    justify-content:right;
    text-align: right;
    font-size: 0.9rem;
    font-weight: bold;
    color: #222b45;
    margin-left: auto;
  }

  .report-logo {
    object-fit: contain;
    display: block;
    max-width: 18rem;
    max-height: 18rem;
  }

  .table-form {
    position:relative;
  }

  .modal-transp {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 20;
  }

  .card-modal {
    border-radius: 1rem;
    margin: 1.5rem;
    padding: 1rem;
    justify-content: center;
  }
  .MDButtonReport{
    width: 100%;
    color: #FFF;
  }